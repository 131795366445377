import { viewport } from "./common";

/**
 * Displays the correct height in the meu
 */
export function manageMenuHeight()
{

  let $menu = $('#menu');

  adjustMenu();

  $menu.addClass('menu-loaded');
}

/**
 *
 */
function adjustMenu()
{
  let $menu = $('#menu');

  if(viewport().height > 768) {
    $menu.find('.bottom').css(
      'top',
      $menu.height() - $('#menu > ul').height() - $menu.find('.bottom').height()
    );
  }

  return;
}


$(document).ready(function(){

  let $menu = $('.v2__sidebar_menu');


  if($('.the-content').hasClass('container-fluid')) {
    enableSmallMenuForSmallScreens();
  }

  var menuAction = true;

  $('.main-page-content').on('click',function() {

    if($menu.is(':visible') && $('#menu-toggle').is(':visible')) {
      $('#menu-toggle').click();
    }

  });

  $('#menu ul li').click(function() {

    if( $(this).hasClass('disabled') ) {
      return;
    }

    if( !menuAction )
    {
      menuAction = true;
      return;
    }

    var targetUrl = $(this).data('url');

    if(targetUrl === "#upgrade-popin") {
      $('#upgrade-popin').show();
      menuAction = false;
      return;
    }

    if( targetUrl !== "" )
    {
      menuAction = false;
      window.location.href = targetUrl;
    }
    else if( $(this).hasClass( "dropdown" ) )
    {
      $(this).toggleClass('hide-submenu');
    }
  });

  $('.v2__menu_mobile_toggle').on('click',function() {
    $menu.toggle(200);
  });

  $('.supervision-menu .supervision-item .banner').click(function(){

    if($(this).hasClass('patient')) {
      return;
    }

    if( $(this).children('span.far').hasClass('fa-angle-right') )
    {
      $('.supervision-menu > .supervision-item > .banner > span.fa-angle-down').removeClass('fa-angle-down');
      $('.supervision-menu > .supervision-item > .banner > span.far').addClass('fa-angle-right');
      $('.supervision-menu .supervision-content').hide();

      $(this).children('span.far').removeClass('fa-angle-right');
      $(this).children('span.far').addClass('fa-angle-down');

      $(this).parent().children('.supervision-content').show();

    }
    else
    {
      $('.supervision-menu > .supervision-item > .banner > span.fa-angle-down').removeClass('fa-angle-down');
      $('.supervision-menu > .supervision-item > .banner > span.far').addClass('fa-angle-right');
      $('.supervision-menu .supervision-content').hide();

      $(this).children('span.far').removeClass('fa-angle-down');
      $(this).children('span.far').addClass('fa-angle-right');

      $(this).parent().children('.supervision-content').hide();
    }
  });

  $('.glyphicon-search').click(function(){
    $(this).parent('form').submit();
  });

  if(window.smallMenu) {
    enableSmallMenu();
  }

});


function enableSmallMenuForSmallScreens() {

  if(viewport().width < 1200) {
    toggleSmallMenu(true);
  } else {
    toggleSmallMenu(false);
  }

  $(window).off('resize').on('resize',enableSmallMenuForSmallScreens);

}

/**
 * Enable the small menu
 */
export function enableSmallMenu()
{
  toggleSmallMenu(true);

  $(window).off('resize').on('resize',enableSmallMenu);
}


/**
 *
 */
export function toggleSmallMenu(enable) {

  let $menu = $('#menu');

  if(enable) {
    shrinkMenu();
    $menu.hover(expandMenu,shrinkMenu);
  } else {
    expandMenu();
    $menu.off('mouseenter mouseleave');
  }

}


/**
 * Expand the menu to its normal width
 */
function expandMenu() {

  let $menu = $('#menu');
  let $content = $('.main-page-content');

  $menu.removeClass('small');
  $content.removeClass('large');
  adjustMenu();
}

/**
 * Shrinks the menu
 */
function shrinkMenu() {
  adjustMenu();
}
