import {
  buildId, GET, getUrlParam, handleViolations, isFakeEmail, isHospitalStaff,
  parseId, v2DisplayImage
} from "../common";
import { HTTP } from "../utils";
import TagsAutocompleter from "../TagsAutocompleter";
import { getFieldValue } from "../form-utils";
import HealthCardReader from "./health-card-reader";
import ConflictManager from "../patient-profile/conflict-manager";
import {
  COMMON_ACTIONS_CONTINUE, PATIENT_CONSULTATIONS_CREATED, PATIENT_CONSULTATIONS_FOUND, PATIENT_CONSULTATIONS_LOADING,
  PATIENT_CONSULTATIONS_NEW, PATIENT_CONSULTATIONS_NOT_FOUND,
  PATIENT_HELPER_ALREADY_EXIST, PATIENT_HELPER_ASSOCIATION, PATIENT_HELPER_CREATED, PATIENT_HELPER_CREATION,
  PATIENT_HELPER_ENTER_MANUALLY,
  PATIENT_HELPER_FILE_CREATED,
  PATIENT_HELPER_FILL_INFO, PATIENT_HELPER_FOUND, PATIENT_HELPER_FOUND_SEVERAL,
  PATIENT_HELPER_IGNORED,
  PATIENT_HELPER_INFO_FILLED, PATIENT_HELPER_INFO_SAVED, PATIENT_HELPER_SAME, PATIENT_HELPER_SAME_SECURITY_NUMBER,
  PATIENT_HELPER_SAVE_INFO,
  PATIENT_HELPER_SEARCHING,
  PATIENT_HELPER_SELECT, PATIENT_HELPER_SELECT_BENEFICIARIES,
  PATIENT_HELPER_USER_SELECTED,
  trans
} from "../../../translator";

export default class ConsultationCreator extends HealthCardReader {


  isConsultationFlow = true;
  PANEL_MANUAL_DATA = "manual_patient_selection";
  PANEL_PATIENT_CONFIRMATION = "patient_confirmation";
  PANEL_PATIENT_COMMUNICATION = "patient_communication";
  PANEL_CONSULTATION_CREATION = "consultation_creation";

  title = trans(PATIENT_CONSULTATIONS_NEW, {}, 'patient');


  $openButton = $('.new-consultation-in-drawer');


  init() {


    if(!isHospitalStaff()) {
      return;
    }

    super.init();

    if (getUrlParam('new_consultation')) {
      this.open();
    }

  }

  readHealthCard() {
    if (enabledModules.patient_billing) {
      super.readHealthCard();
    } else {
      this.fillEnterDataManually();
    }
  }


  async fillMissingHealthCardModal() {

    const panelId = this.PANEL_HEALTH_CARD;
    const panel = this.getPanel(panelId);

    super.fillMissingHealthCardModal();

    panel.find(".enter-data-manually").show();

    panel.find(".enter-data-manually").off('click').on('click', (e) => {
      e.preventDefault();
      this.checkPanel(panelId, trans(PATIENT_HELPER_ENTER_MANUALLY, {}, 'patient'));
      e.preventDefault();
      this.fillEnterDataManually();
    });

  }

  fillEnterDataManually() {
    const panelId = this.PANEL_MANUAL_DATA;

    this.openPanel(panelId, trans(PATIENT_HELPER_SELECT, {}, 'patient'));

    const panel = this.getPanel(panelId);
    const button = panel.find('.continue');
    button.prop('disabled', true);

    const tagsInput = new TagsAutocompleter(panel.find('#drawer_consultation_patient'));

    tagsInput.init();

    tagsInput.clear();

    tagsInput.onItemAdd = () => {
      button.prop('disabled', false);
    };

    tagsInput.onItemRemove = () => {
      button.prop('disabled', false);
    };

    button.off('click').on('click', async () => {

      const patient = await tagsInput.value.data;

      this.checkPanel(panelId, trans(PATIENT_HELPER_USER_SELECTED, { '$name': patient.user.fullName }, 'patient'));
      this.getConsultationOfTheDay(patient);
    });

  }


  async onPatientSelected(healthCardPatient) {

    let patient;

    const panelId = this.PANEL_PATIENT_CONFIRMATION;
    this.openPanel(panelId, trans(PATIENT_HELPER_SEARCHING, {}, 'patient'), true);

    try {

      const query = this.addOwnerToQuery({
        id: parseId(healthCardPatient['@id'])
      });

      patient = await this.POST(
        Routing.generate("api_patient_billing_patients_post_item", query),
        {}, null, { silently: true });
    } catch (e) {
      if (e.status === HTTP.STATUS_CODE.CONFLICT) {

        const { field } = e.responseJSON;

        if (field === "name") {
          this.fillExistingPatientModal(healthCardPatient, e.responseJSON.object);
        } else {
          this.fillExistingSSNPatientModal(healthCardPatient, e.responseJSON);
        }
      }
      throw e;
    }


    const createdDate = new Date(patient.user.createdDate);

    if (createdDate > (new Date().getTime() - 1000 * 60 * 60)) {
      this.checkPanel(panelId, trans(
        PATIENT_HELPER_FILE_CREATED,
        { '$name': patient.user.fullName },
        'patient'
      ), true);
    } else {
      this.checkPanel(panelId, trans(
        PATIENT_HELPER_ALREADY_EXIST,
        { '$name': patient.user.fullName },
        'patient'
      ), true);
    }

    this.fillContactInformationModal(patient);

  }


  fillContactInformationModal(patient) {
    const panelId = this.PANEL_PATIENT_COMMUNICATION;

    const panel = this.getPanel(panelId);

    panel.find('input').val('').prop('checked', true);

    const hasPhone = patient.user.phone !== null;
    const hasEmail = !isFakeEmail(patient.user.email);

    panel.find('.phone').toggle(!hasPhone);
    panel.find('.email').toggle(!hasEmail);

    if (hasPhone && hasEmail) {
      this.checkPanel(panelId, trans(PATIENT_HELPER_INFO_FILLED, {}, 'patient'), false);
      this.getConsultationOfTheDay(patient);
      return;
    }

    this.openPanel(panelId, trans(PATIENT_HELPER_FILL_INFO, {}, 'patient'));

    panel.find('.ignore-communication').off('click').on('click', (e) => {

      e.preventDefault();

      this.checkPanel(panelId, trans(PATIENT_HELPER_IGNORED, {}, 'patient'), true);
      this.getConsultationOfTheDay(patient);
    });

    panel.find('form').off('submit').on('submit', async (e) => {

      e.preventDefault();

      const data = {
        user: {
          id: buildId("users", patient.user['@id']),
          sendInvitationEmail: getFieldValue(panel.find('.email input[type="checkbox"]')),
          sendInvitationSMS: getFieldValue(panel.find('.phone input[type="checkbox"]')),
        },
      };

      const email = panel.find('.email input[type="email"]').val();
      if (email) {
        data.user.email = email;
      }

      const phone = getFieldValue(panel.find('.phone input[type="tel"]'));
      if (phone) {
        data.user.phones = [{
          number: phone,
        }];
      }

      const save = async (formData) => {
        this.openPanel(panelId, trans(PATIENT_HELPER_SAVE_INFO, {}, 'patient'), true);
        return await this.PUT(Routing.generate("api_patients_put_item", { id: patient.id }), formData);
      };

      try {
        await save(data);

        this.checkPanel(panelId, trans(PATIENT_HELPER_INFO_SAVED, {}, 'patient'), true);
        this.getConsultationOfTheDay(patient);

      } catch (e) {

        if (e.status === HTTP.STATUS_CODE.CONFLICT) {

          const conflictManager = new ConflictManager(patient);
          conflictManager.save = save;

          conflictManager.onRedirect = async (profile) => {

            conflictManager.mergeModal.hide();
            conflictManager.conflictModal.hide();

            this.checkPanel(panelId, trans(PATIENT_HELPER_INFO_SAVED, {}, 'patient'), true);

            if(!profile.id) {
              profile = await GET(Routing.generate('api_users_profile_item', {
                id: profile.user.id,
              }));
            }

            this.getConsultationOfTheDay(profile);
          };

          conflictManager.handleConflict(e.responseJSON, data);
        }

        this.openPanel(panelId, trans(PATIENT_HELPER_FILL_INFO, {}, 'patient'));

        handleViolations(e, null, panel, {
          "email": "patient_communication_email"
        });

        throw e;

      }

    });


  }


  async fillExistingPatientModal(healthCardPatient, userIds) {
    const panelId = this.PANEL_PATIENT_CONFIRMATION;

    userIds = userIds.split(',');

    const promises = [];


    this.openPanel(panelId, userIds.length === 1 ?
      trans(PATIENT_HELPER_FOUND, {}, 'patient') :
      trans(PATIENT_HELPER_FOUND_SEVERAL, {}, 'patient'),
    true);

    userIds.forEach((userId) => {
      promises.push(this.GET(Routing.generate('api_users_profile_item', {
        id: parseId(userId),
      })));
    });

    let patients = await Promise.all(promises);

    const panel = this.getPanel(panelId);
    this.openPanel(panelId);

    const $similarButton = panel.find('.similar');

    panel.find('.patients').html('');


    let selectedPatient;

    patients.forEach((patient) => {
      const $template = $($("#existing-patient-selection-template").html());

      v2DisplayImage(patient.user.thumbnails.profilePicture['50x50'], $template.find('.avatar'), null, patient.user);

      $template.find('.name').html(patient.user.fullName);
      $template.find('.age-data').html(new Date(patient.user.dateOfBirth).toLocaleDateString());
      $template.find('.email-data').html(patient.user.email);
      $template.find('.phone-data').html(patient.user?.nationalNumber?.number);
      $template.find('.link-to-profile').attr('href', this.createPatientUrl(patient));

      const $checkbox = $template.find('input[type="radio"]');
      if (patients.length === 1) {
        $checkbox.prop('checked', true);
        selectedPatient = patient;
      }

      $checkbox.on('change', () => {
        $similarButton.prop('disabled', false);
        selectedPatient = patient;

      });
      panel.find('.patients').append($template);

    });

    $similarButton.html(patients.length === 1 ?
      trans(PATIENT_HELPER_SAME, {}, 'patient') :
      trans(COMMON_ACTIONS_CONTINUE, {}, 'common')
    );
    $similarButton.prop('disabled', selectedPatient === undefined);

    panel.find('.different').off('click').on('click', (e) => {
      e.preventDefault();
      this.ignoreExisting(healthCardPatient);
    });

    $similarButton.off('click').on('click', (e) => {
      e.preventDefault();
      this.mergePatients(healthCardPatient, selectedPatient);
    });

  }


  async fillExistingSSNPatientModal(healthCardPatient, responseData) {
    const panelId = this.PANEL_PATIENT_CONFIRMATION;

    const { object, objectName, hasAccess } = responseData;

    this.openPanel(panelId, trans(PATIENT_HELPER_SAME_SECURITY_NUMBER, {}, 'patient'),
      true);


    let patient;

    if (hasAccess) {
      patient = await this.GET(Routing.generate('api_users_profile_item', {
        id: parseId(object),
      }));
    } else {
      patient = {
        user: {
          "@id": object,
          id: parseId(object),
          fullName: objectName,
        }
      };
    }

    const panel = this.getPanel(panelId);
    this.openPanel(panelId);

    const $similarButton = panel.find('.similar');

    panel.find('.patients').html('');

    const $template = $($("#existing-patient-selection-template").html());

    v2DisplayImage(patient.user?.thumbnails?.profilePicture['50x50'], $template.find('.avatar'), null, patient.user);

    $template.find('.name').html(patient.user.fullName);
    $template.find('.age-data').html(patient.user.dateOfBirth ?
      new Date(patient.user.dateOfBirth).toLocaleDateString() : "");
    $template.find('.email-data').html(patient.user?.email);
    $template.find('.phone-data').html(patient.user?.nationalNumber?.number);
    if (hasAccess) {
      $template.find('.link-to-profile').attr('href', this.createPatientUrl(patient));
    } else {
      $template.find('.link-to-profile').remove();
    }

    const $checkbox = $template.find('input[type="radio"]');
    $checkbox.prop('checked', true);

    panel.find('.patients').append($template);

    $similarButton.html(trans(PATIENT_HELPER_SAME, {}, 'patient'));

    panel.find('.different').off('click').on('click', (e) => {
      e.preventDefault();
      this.ignoreExisting(healthCardPatient);
    });

    $similarButton.off('click').on('click', (e) => {
      e.preventDefault();
      this.mergePatients(healthCardPatient, patient);
    });

  }

  async ignoreExisting(healthCardPatient) {

    const panelId = this.PANEL_PATIENT_CONFIRMATION;

    this.openPanel(panelId, trans(PATIENT_HELPER_CREATION, {}, 'patient'), true);

    const query = this.addOwnerToQuery({
      id: parseId(healthCardPatient['@id'])
    });

    const patient = await this.POST(Routing.generate("api_patient_billing_patients_post_item", query), {
      ignoreExisting: true
    });

    this.checkPanel(panelId, trans(PATIENT_HELPER_CREATED, {}, 'patient'), true);

    this.fillContactInformationModal(patient);

  }


  async mergePatients(healthCardPatient, patient) {
    const panelId = this.PANEL_PATIENT_CONFIRMATION;

    this.openPanel(panelId, trans(PATIENT_HELPER_ASSOCIATION, {}, 'patient'), true);

    const query = this.addOwnerToQuery({
      id: parseId(healthCardPatient['@id'])
    });

    patient = await this.POST(Routing.generate("api_patient_billing_patients_post_item", query), {
      user: patient.user['@id']
    });

    this.checkPanel(panelId, trans(PATIENT_HELPER_ASSOCIATION, { '$name': patient.user.fullName }, 'patient'), true);

    this.fillContactInformationModal(patient);
  }


  fillHealthCardModal(patients) {

    const panelId = this.PANEL_PATIENT_SELECTION;

    this.openPanel(panelId, trans(PATIENT_HELPER_SELECT_BENEFICIARIES, {}, 'patient'));

    this.$drawer.find('.patient-selection').html('');

    patients.forEach((patient) => {
      const $template = $($("#patient-selection-template").html());

      $template.find('.name').html(patient.fullName);
      $template.find('.age').html(`${patient.age} ans`);
      $template.find('.dob').html(new Date(patient.dateOfBirth).toLocaleDateString());
      $template.find('.ssn').html(patient.socialSecurityNumber);
      v2DisplayImage(null, $template.find('.avatar'), null, patient);

      $template.off('click').on('click', (e) => {
        e.preventDefault();
        this.checkPanel(panelId, trans(PATIENT_HELPER_USER_SELECTED, { '$name': patient.fullName }, 'patient'));
        this.onPatientSelected(patient);
      });

      this.$drawer.find('.patient-selection').append($template);

    });

    this.$drawer.find("#patient_selection").show();


  }


  async getConsultationOfTheDay(patient) {

    const panelId = this.PANEL_CONSULTATION_CREATION;

    this.openPanel(panelId, trans(PATIENT_CONSULTATIONS_LOADING, {}, 'patient'), true);

    const today = new Date();
    today.setHours(0, 0, 0);

    const query = this.addOwnerToQuery({
      page: 1,
      _per_page: 1,
      id: parseId(patient['@id']),
      createdDate: {
        after: today.toISOString(),
      }
    }, true);

    const consultations = await GET(
      Routing.generate("api_patients_consultations_get_subresource", query),
      this.$drawer, null, this.setCurrentRequest);

    if (consultations['hydra:member'].length !== 0) {

      const consultation = consultations['hydra:member'][0];

      this.checkPanel(panelId, trans(PATIENT_CONSULTATIONS_FOUND, {}, 'patient'), false);
      this.redirectToProfile(patient, consultation);
      return;
    }

    this.createConsultationForToday(patient);

  }

  async createConsultationForToday(patient) {

    const panelId = this.PANEL_CONSULTATION_CREATION;

    this.openPanel(panelId, trans(PATIENT_CONSULTATIONS_NOT_FOUND, {}, 'patient'), true);

    const data = this.addOwnerToQuery({
      patient: buildId("patients", patient['@id']),
    },true);

    const consultation = await this.POST(Routing.generate('api_consultations_post_collection'), data);

    this.checkPanel(panelId, trans(PATIENT_CONSULTATIONS_CREATED, {}, 'patient'), true);

    this.redirectToProfile(patient, consultation);

  }


  checkPanel(panelId, subtitle, blockOpening) {

    const panel = this.getPanel(panelId);

    panel.show();

    panel.find(`.loader`).hide();
    panel.find(`.check`).show();
    panel.find('.error').hide();
    const checkbox = panel.find(`.toggle_input`);
    checkbox.prop('checked', false);
    checkbox.prop('disabled', !!blockOpening);

    if (subtitle) {
      panel.find('.subtitle').html(subtitle);
    }

  }


  createPatientUrl(patient, consultation) {
    return Routing.generate('app_patient_user_profile', {
      id: parseId(patient.user['@id']),
      consultation: consultation ? parseId(consultation['@id']) : null
    }) + '#consultations';
  }

  redirectToProfile(patient, consultation) {

    const url = this.createPatientUrl(patient, consultation);

    if (document.location.href.endsWith(url)) {
      this.close();
    }

    document.location.assign(url);
  }

  resetAlerts() {
    this.$drawer.find('.popin_alerts').html('');
  }

}
