import EntityList from "../entity-list";
import { openDrawer } from "../rightPanel";
import { GET, isFakeEmail, v2DisplayImage } from "../common";
import { COMMON_MESSAGE_NO_RESULTS, PATIENT_HELPER_RECENT, trans } from "../../../translator";


class PatientSearcher extends EntityList
{
  $drawer = $('#patients-list--drawer');

  $loader = this.$drawer.find('.loader');

  entity_type = 'patient_list';

  $box = this.$drawer.find('.items');

  $button = $('.patient-search--button');

  $search = this.$drawer.find('input[type="search"]');

  get row() {
    return $($('#patient-search-template').html());
  }

  async init() {
    if (!current_user) {
      return;
    }

    this.registerEvents();

  }


  async loadData() {
    this.$loader.show();

    super.loadData(true);

    const search = this.$search.val();

    const result = await GET(Routing.generate('api_patients_get_collection',{
      search,
      favorites: !search
    }));

    if(!search) {
      this.$box.append('<h4 class="data patient-recent-text">' + trans(PATIENT_HELPER_RECENT, {}, 'patient') + '</h4>');
    }

    result['hydra:member'].forEach((patient) => {
      this.addTemplate(patient);
    });

    this.displayNotFoundPanel();

    this.$loader.hide();

  }

  addTemplate(element) {
    super.addTemplate(element);

    const dob = element.user.dateOfBirth ? new Date(element.user.dateOfBirth) : null;

    const email = isFakeEmail(element.user.email) ? null : element.user.email;

    const $template = this.getTemplate(element);

    $template.find('.name').html(element.user.fullName);
    $template.find('.subtitle')
      .html([dob?.toLocaleDateString(),email].filter(Boolean).join(' - '));

    $template.addClass('data');

    v2DisplayImage(
      element.user.thumbnails.profilePicture['50x50'],
      $template.find('.avatar'),
      null,
      element.user
    );

    $template.find('a').attr('href',Routing.generate('app_patient_user_profile',{
      id: element.user.id
    }));

    this.insertTemplate($template);

  }


  registerEvents() {
    super.registerEvents();

    this.$button.on('click', () => {
      openDrawer(this.$drawer);
      this.$box.html('');
      this.loadData();
    });

    let timeout;

    this.$search.on('keyup', () => {

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        this.loadData();
      }, 300);

    });

  }

  get notFoundPanel() {
    return $('<h4 class="data patient-recent-text">' + trans(COMMON_MESSAGE_NO_RESULTS, {}, 'common') + '</h4>');
  }

}

export default PatientSearcher;