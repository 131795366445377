import EntityList from "../entity-list";
import { bindEvent, createMercureConnection, formatDate, GET, POST, PUT } from "../common";
import { close as closeRightPanel, isOpen as isOpenRightPanel, open as openRightPanel } from "../rightPanel";
import { v4 as uuidv4 } from 'uuid';
import { DEVICES } from "../utils";
import { COMMON_MESSAGE_COMPUTER_OF, trans } from "../../../translator";

export default class NotificationsList extends EntityList {

  $drawer = $('.notification--drawer');
  $box = this.$drawer.find('.items');
  $clearer = this.$drawer.find('.notification--clearer');

  $loader = this.$drawer.find('.loader');

  $bell = $('.bell');
  $push = $('.push');

  floatingNotification = $('.floating-notification-container');

  entity_type = "notifications";

  badges;

  get row() {
    return $($('#notification-template').html());
  }


  async init() {
    if (!current_user) {
      return;
    }

    this.initPushNotification();

    this.registerEvents();

    await this.loadBadges();
    this.startMercure();

  }


  initPushNotification() {
    bindEvent(window, 'message', async (e) => {

      if (!e.data) {
        return;
      }

      if (!e.data.origin || e.data.origin !== 'instamed') {
        return;
      }

      switch (e.data.type) {
        case "onesignal.push.enabled":
          if (!e.data.value) {
            $('.push').show();
          }
          break;
        case "onesignal.push.userId":
          if (e.data.value.enabled) {
            await POST(Routing.generate('api_devices_post_collection'), {
              oneSignalId: e.data.value.token,
              name: this.generateDeviceName(),
              deviceUniqId: this.generateUniqId(),
              type: DEVICES.TYPE.WEB
            }, null, {}, null, { silently: true });
          }
          break;

      }

    });

  }


  /**
     *
     * @return {string}
     */
  generateDeviceName() {
    let name = trans(COMMON_MESSAGE_COMPUTER_OF, {}, 'common');

    if (current_user) {
      name += current_user.fullName;
    }

    return name;

  }

  /**
     *
     * @return {string|any}
     */
  generateUniqId() {
    const key = `device_uniq_id-${current_user.id}`;
    let id = localStorage.getItem(key);

    if (id) {
      return id;
    }

    id = uuidv4();

    localStorage.setItem(key, id);

    return id;
  }


  registerEvents() {
    super.registerEvents();

    this.$bell.on('click', (e) => {
      this.openPanel(e);
    });

    this.$clearer.on('click', (e) => {
      this.clearAll(e);
    });

    this.$push.on('click', (e) => {
      this.managePushNotification(e);
    });


    $('body').on('click', (e) => {
      this.managePanel(e);
    });

  }


  async loadBadges() {
    let badges = await GET(Routing.generate('api_badges_get'));

    this.handleBadges(badges);
  }


  async loadData(reset, silently) {
    super.loadData(reset, silently);

    let data = await GET(Routing.generate('api_notifications_get_collection', {
      page: this.page,
      archived: 'false',
    }));

    this.postLoad(data, silently);

  }


  /**
     *
     * @param element
     * @param prepend
     */
  addTemplate(element, prepend) {

    let $template = this.getTemplate(element);

    $template = this.fillTemplate($template, element);

    this.addTemplateEvents($template, element);

    this.addData(element, $template);
    this.insertTemplate($template, prepend);

    this.$bell.show();

    if (element.type === "alert.new_for_patient" && !element.read) {
      this.addAlertTemplate(element);
    }
  }


  /**
     *
     * @param $template
     * @param element
     */
  fillTemplate($template, element) {

    $template.find('.n-title').html(element.title);
    $template.find('.n-content').html(element.content).attr('title', element.content);

    let date = formatDate(element.createdDate);

    $template.find('.date').html(date);

    if (!element.read) {
      $template.addClass('n-unread');
    } else {
      $template.find('.mark-as-read').remove();
    }

    $template.addClass('data');

    return $template;

  }


  addTemplateEvents($template, element) {
    $template.find('.mark-as-read').off('click').on('click', () => {
      this.markAsRead(element, $template);
    });

    $template.find('.link').off('click').on('click', async () => {
      if (!element.read) {
        await this.markAsRead(element, $template);
      }

      if (element.data.url) {
        document.location.href = element.data.url;
      }
    });

    return $template;

  }


  addAlertTemplate(notification) {
    $('.s-alert-container').find('.alert-notification').remove();

    $('.s-alert-container').append(`<div class="alert alert-notification alert-success fade in f-16 orange mb-1" 
role="alert">${notification.content}</div>`);

    // Remove duplicate
    $('.as-toast .alert-notification').remove();

  }


  /**
     *
     * @param notification
     * @param $template
     * @return {Promise<void>}
     */
  async markAsRead(notification, $template) {

    await PUT("/api/v2/notifications/" + notification.id, {
      "read": true
    });

    this.markTemplateAsRead($template);

  }


  /**
     *
     * @param $template
     */
  markTemplateAsRead($template) {

    $template.removeClass('n-unread');
    $template.find('.mark-as-read').remove();

    if ($template.hasClass("floating")) {
      $template.remove();
    }

  }


  /**
     *
     */
  displayNotFoundPanel() {
    if (this.data.length === 0) {
      this.$bell.hide();
    }
  }


  /**
     *
     * @param e
     */
  openPanel(e) {

    e.preventDefault();

    if (isOpenRightPanel()) {
      return closeRightPanel();
    }

    this.$drawer.css("display", "flex").css('flex-direction', 'column').show();
    openRightPanel();

    this.loadData(true);

  }


  /**
     *
     * @param e
     */
  managePushNotification(e) {
    e.preventDefault();
    window.open('https://' + main_domain + Routing.generate('app_one_signal_iframe'));
  }


  /**
     *
     * @param e
     * @return {Promise<void>}
     */
  async clearAll(e) {

    e.preventDefault();

    await POST(Routing.generate('api_notifications_clear'));

    this.$drawer.find('.n-unread').each((i, template) => {

      let $template = $(template);
      $template.removeClass('n-unread');
      $template.find('.mark-as-read').remove();
    });

  }

  /**
     *
     * @param e
     */
  managePanel(e) {
    let $elem = $(e.target);

    if (
      $elem.closest('.notification--drawer').length === 0 &&
            ($elem.closest('.bell').length === 0) &&
            $elem !== this.$bell) {

      closeRightPanel(() => this.$drawer.hide());
    }

  }

  /**
     *
     * @return {Promise<void>}
     */
  async startMercure() {

    // Notifications
    createMercureConnection(`/jsonld/users/${current_user.id}/notifications`, (e) => {
      this.addFloatingTemplate(JSON.parse(e.data));
    });

    // Badges
    createMercureConnection(`/users/${current_user.id}/badges`, e => {
      this.handleBadges(JSON.parse(e.data));
    });

  }


  /**
     *
     * @param badges
     */
  handleBadges(badges) {

    const unarchived = badges.discussions.ungrouped + badges.discussions.grouped;

    $('.badge-notifications-unread').html(badges.notifications.unread)
      .toggle(badges.notifications.unread !== 0);

    $('.badge-notifications-panel-unread').html(badges.notifications.unread);
    $('.badge-discussions-total').html(badges.discussions.total).css(
      { opacity: badges.discussions.total !== 0 ? 1 : 0 });
    $('.badge-mssante-total').html(badges.mssante.total).css(
      { opacity: badges.mssante.total !== 0 ? 1 : 0 });
    $('.badge-discussions-ungrouped').html(badges.discussions.ungrouped).toggle(badges.discussions.ungrouped !== 0);
    $('.badge-discussions-grouped').html(badges.discussions.grouped).toggle(badges.discussions.grouped !== 0);
    $('.badge-discussions-unarchived').html(unarchived).toggle(unarchived !== 0);
    $('.badge-discussions-archived').html(badges.discussions.archived).toggle(badges.discussions.archived !== 0);

    if (badges.faqs) {
      let total = badges.faqs.unread + badges.tips.unread;
      $('.badge-faq-unread').html(total).css({ opacity: total !== 0 ? 1 : 0 });
    }

  }


  /**
     *
     * @param notification
     */
  addFloatingTemplate(notification) {

    let $template = this.getTemplate(notification, "floating_notification", this.floatingNotification);

    // Add it in the list
    this.addTemplate(notification, EntityList.PREPEND_IF_NOT_EXIST);

    if (notification.read) {
      $template.remove();
      return;
    }

    this.fillTemplate($template, notification);

    $template.addClass('floating');

    this.addTemplateEvents($template, notification);

    this.floatingNotification.append($template);
    $template.hide();
    $template.fadeIn(300);


    $template.find('.close').off('click').on('click', (e) => {
      this.removeTemplate($template);
      e.preventDefault();
    });

    setTimeout(() => {
      this.removeTemplate($template);
    }, 15 * 1000);

    const audio = new Audio('/build/sounds/notification.mp3');
    audio.play();

  }


  removeTemplate($template) {
    $template.fadeOut(300, () => {
      $template.remove();
    });
  }


}
