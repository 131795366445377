import { GET, getParameterByName } from "./common";
import DocumentUploader from "./documents/document-uploader";
// Document const

$(document).ready(async function() {


  let d = getParameterByName('documentId');

  if(d) {
    let doc = await GET('/api/v2/documents/' + d);
    DocumentUploader.openDocument(doc);
  }

  $('body').on('click','.open-in-modal',async function(e) {

    let link = $(this).attr('href');

    let document = $(this).attr('document');

    let documentId = $(this).attr('documentId');

    e.preventDefault();

    if(documentId) {
      let document = await GET(Routing.generate('api_documents_get_item',{ id : documentId }));
      DocumentUploader.openDocument(document);
      return;
    }

    // Fallback
    if(document) {
      link = document;
    }

    DocumentUploader.openDocument(link);


  });


});
