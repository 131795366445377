import { COMMON_WORDS_MEDIUM, COMMON_WORDS_STRONG, COMMON_WORDS_WEAK, trans } from "../../translator";

class PasswordChecker
{
  get min_length() { return 8; }

  get patterns() {return {
    'is_upper' : /[A-Z]/,
    'is_number' : /[0-9]/,
    'is_symbol' : /(.*[!,@#$%^&*?_~])/,
    'flat_lower' : /^[\sa-z]+$/,
    'flat_upper' : /^[\sA-Z]+$/,
    'flat_number' : /^[\s0-9]+$/,
  };
  }

  get strengths() {
    return [
      trans(COMMON_WORDS_WEAK, {}, 'common'),
      trans(COMMON_WORDS_MEDIUM, {}, 'common'),
      trans(COMMON_WORDS_STRONG, {}, 'common')
    ];
  }

  get classes() {
    return [
      'weak','medium','strong'
    ];
  }


  get passwords() {
    return $('input[type="password"]:not(".login-password")');
  }


  /**
     *
     */
  init()
  {
    this.passwords.each((k,elem) => {

      let $elem = $(elem);

      $elem.after('<p class="password-checked"></p>');

      $elem.on('input',(event) => {
        this.onChange(event);
      });

    });
  }



  /**
     *
     * @param event
     * @private
     */
  onChange(event)
  {
    let input = $(event.target);

    let checker = input.parent().find('.password-checked');

    let strength = this.getStrength(this.analyse(input));

    let string = this.strengths[strength];

    checker.removeClass(this.classes.join(' '));

    let $class = this.classes[strength];

    checker.addClass($class);
    checker.html(string);
  }



  analyse(input) {

    let password = input.val();
    let score = 0;

    if(!password) {
      return score;
    }

    if(password.length >= this.min_length) {
      score += 40;
    }


    let $num = {
      'excess': 0,
      'upper': 0,
      'symbols': 0,
      'numbers': 0,
    };

    let $bonus = {
      'excess' : 1,
      'upper' : 4,
      'numbers' : 5,
      'symbols' : 5,
      'combo' : 0,
      'flat_lower' : 0,
      'flat_number' : 0,
      'flat_upper' : 0,
      'same_char' : 0,
    };


    password.split('').forEach((char) => {

      if (char.match(this.patterns.flat_number))
      {$num['numbers']++;}

      if (char.match(this.patterns.is_symbol))
      {$num['symbols']++;}

      if (char.match(this.patterns.is_upper))
      {$num['upper']++;}
    });

    // Check the number over the min length
    $num['excess'] = password.length - this.min_length;


    if($num['upper'] && $num['numbers'] && $num['symbols']){
      $bonus['combo'] = 25;
    }else if($num['upper'] && $num['numbers'] ||
        $num['upper'] && $num['symbols'] || $num['numbers'] && $num['symbols']){
      $bonus['combo'] = 15;
    }


    if(password.match(this.patterns.flat_upper))
    {$bonus['flat_lower'] = -25;}

    if(password.match(this.patterns.flat_number))
    {$bonus['flat_number'] = -25;}
    if(password.match(this.patterns.flat_upper))
    {$bonus['flat_upper'] = -25;}


    // Calculate the score
    score = score + $num['excess']*$bonus['excess'] +
            $num['upper']*$bonus['upper'] +
            $num['numbers']* $bonus['numbers'] +
            $num['symbols']*$bonus['symbols'] +
            $bonus['combo'] +
            $bonus['flat_lower'] +
            $bonus['flat_number'] +
            $bonus['flat_upper'] +
            $bonus['same_char'];


    return score;
  }



  /**
     *
     * Return the strength of the password
     *
     * @param score
     * @return {number}
     */
  getStrength(score)
  {
    if (score > 100) {
      return 2;
    }

    if (score > 50) {
      return 1;
    }

    return 0;
  }



}

$(document).ready(() => {

  let $pc = new PasswordChecker();

  $pc.init();

});
