import ConsultationCreator from "./consultation-creator";
import PatientSearcher from "./patient-searcher";
import NotificationsList from "./notifications-list";


$(document).ready(() => {
  const creator = new ConsultationCreator();
  creator.init();

  const searcher = new PatientSearcher();
  searcher.init();

});


$(window).on('load', () => {
  let $notification = new NotificationsList();
  $notification.init();
});
