import copy from 'copy-to-clipboard';
import { trans, USER_COPIED_LINK } from "../../translator";

export const reinitCopyButton = ($button) => {
  $button.removeClass('btn-success');
  $button.addClass('btn-primary');
  $button.text('Copier le lien');
};

const copyDone = ($button) => {
  $button.addClass('btn-success');
  $button.text(trans(USER_COPIED_LINK, {}, 'user'));
};

export default () => {
  $('.copyToBuffer').on('click',function() {
    const text = $(this).data('copy-value');
    copy(text);
    copyDone($(this));
  });
};
