$(document).ready(function() {
  let forms = jQuery('form:not(".ignore-data")');

  forms.on('submit',function() {
    resetValues();
  });

  let band = $('.auto-save-band');

  forms.on('submit',function() {
    resetValues();
  });

  function displayBand() {
    band.fadeIn(200);
  }

  band.find('.ok').on('click',function() {
    restoreValues();
    band.fadeOut(200);
  });

  band.find('.nok').on('click',function() {
    resetValues();
    band.fadeOut(200);
  });


  forms.each(function(k,form) {

    form = $(form);

    let formValue = getFormValue(form);
    let storedValue = getStoredValue(form);

    if(!compareValues(formValue,storedValue)) {
      displayBand();
    }

    form.on('keyup',function() {
      saveValue(form);
    });
    form.on('change',function() {
      saveValue(form);
    });

  });



  function restoreValues() {

    if(!forms.length) {
      return;
    }

    forms.each(function(k,form) {

      form = $(form);

      let storedValue = getStoredValue(form);

      if(!storedValue) {
        return;
      }

      $.each(storedValue,function(key, value) {
        const $input = form.find('[name="'+ key +'"]');
        addValueToInput($input,value);

        $input.toggle('change');
        $input.toggle('keyup');

      });
    });
  }

  function addValueToInput(input, value) {

    if(input.is(':radio') || input.is(':checkbox')) {
      $.each(input,function(k, v) {
        if($(v).attr('value') === value) {
          $(v).prop('checked',true);
          return;
        }
      });
      return;
    }

    if(input.attr('type') === 'file') {
      return;
    }

    input.val(value);
  }

  function resetValues() {

    forms.each(function(k,form) {

      form = $(form);
      saveForForm(form, null);
    });
  }



  /**
     *
     * Compare the values of 2 forms;
     *
     * @param storedValue
     * @param formValue
     * @return {boolean}
     */
  function compareValues(formValue, storedValue) {

    if(!storedValue) {
      return true;
    }

    return JSON.stringify(storedValue) === JSON.stringify(formValue);
  }


  /**
     * Returns the value of a form
     *
     * @param form
     */
  function getFormValue(form) {

    let value = form.serializeArray();

    let val = {};

    value.forEach(function(v) {

      if(v.name.match(/(.)+\[_token]((.)+)?/)) {
        return;
      }

      if($('[name="'+ v.name +'"]').attr('type') == 'password') {
        return;
      }
      val[v.name] = v.value;
    });

    return val;
  }



  /**
     *
     * Get the form value and save it
     *
     * @param form
     */
  function saveValue(form) {

    let value = getFormValue(form);

    saveForForm(form,value);

  }


  $(window).on('load',function() {
    $('form').find('.tinymce').each(function(k, v) {

      let input = $(this);

      try {
        if (window.tinyMCE) {
          window.tinyMCE.get($(v).attr('id')).on('keyup', function() {
            let form = input.closest('form');
            saveValue(form);
          });
        }
      }catch (e) {
        // Do nothing tinyMCE is not defined
      }
    });
  });
});


/**
 *
 * Returns the form id
 *
 * @param form
 * @return {string}
 */
export function getFormId(form) {

  return 'forms.' + form.attr('action') + form.attr('id') + form.attr('name') + document.location.pathname;
}


/**
 *
 * @param form
 * @param value
 */
export function saveForForm(form, value) {
  let uniqId = getFormId(form);

  if(null === value) {
    localStorage.removeItem(uniqId);
  }
  else {
    localStorage.setItem(uniqId, JSON.stringify(value));
  }
}


/**
 *
 * @param form
 * @return {any}
 */
export function getStoredValue(form) {

  let uniqId = getFormId(form);
  return JSON.parse(localStorage.getItem(uniqId));
}
