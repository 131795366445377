import initRightPanel from './src/rightPanel';
import initCopyToBuffer from './src/copyToBuffer';
import { initAmplitude } from "./src/amplitude";

$(document).ready(function() {
  initRightPanel();
  initCopyToBuffer();

  initAmplitude();

});
